<template>
  <div class="be-image-upload-container">
    <img class="be-image-upload" :src="baseUrl + imageSrc" alt="Upload" @click="browseFile" />
    <input type="file" name="file" ref="file" style="display: none" @change="uploadFile" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { uploadService } from '@/apis/upload.s';

export default {
  props: ['src', 'changed'],
  data() {
    return {
      baseUrl: 'https://minio.dcomcy.com/dev',
      imageSrc: this.src,
      imageId: null,
    };
  },
  methods: {
    getData() {
      return {
        imageId: this.imageId,
        imageSrc: this.imageSrc,
      };
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    browseFile() {
      $(this.$refs.file).trigger('click');
    },
    async uploadFile(e) {
      const idx = this.selected;
      let file = e.target.files.length > 0 ? e.target.files[0] : null;
      if (!file) return;
      e.target.value = null;
      try {
        let parts = file.name.toLowerCase().split('.');
        if (parts.length < 2) return;
        let type = parts[parts.length - 1];
        let id = this.objectId();
        let urlData = await uploadService.get({ filename: `image-${id}.${type}` });
        if (urlData) {
          let res = await axios.put(urlData.data.presignedUrl, file, {
            headers: {
              'Content-Type': `${file.type}`,
            },
          });
          this.imageSrc = urlData.data.imagePath;
          this.imageId = id;
          if (typeof this.changed === 'function') this.changed(this.getData());
        }
      } catch (err) {
        this.errors = err;
      }
    },
  },
};
</script>

<style lang="scss">
.be-image-upload-container {
  position: relative;
  display: block;
  width: 144px;
  height: 144px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: #ddd;
  }
}
.be-image-upload {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 144px;
  text-align: center;
  color: #ccc;
  overflow: hidden;
}
</style>
