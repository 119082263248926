<template>
  <v-row no-gutters>
    <v-col :cols="cols || 2" v-for="(item, idx) in items" :key="idx" class="ma-0 pa-1">
      <div class="be-avt-container">
        <img
          v-if="item.dataValue && item.dataValue !== ''"
          class="be-avt dataValue"
          :src="imageHelpers.url(item.dataValue, { width: 500, height: 500 })"
          alt="Upload"
          @click="browseFile(idx)"
        />
        <img
          v-else
          width="50px"
          height="50px"
          style="height: 50px; margin-top: 35px"
          class="be-avt"
          :src="imageFake.Emty"
          alt="Upload"
          @click="browseFile(idx)"
        />
        <span class="be-avt-code">{{ item.dataCode || '' }}</span>
        <span @click="deleteItem(idx)" class="be-avt-del"><i class="fas fa-trash"></i></span>
      </div>
    </v-col>
    <input type="file" ref="file" name="file" @change="uploadFile" style="display: none" />
  </v-row>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { uploadService } from '@/apis/upload.s';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';

export default {
  props: ['cols', 'group', 'changed'],
  data() {
    const limit = 6;
    let items = this.group.data.slice(0, limit);
    for (let i = items.length; i < limit; i++) {
      items.push({});
    }
    return {
      imageFake,
      imageHelpers,
      baseUrl: 'https://minio.dcomcy.com/hiweb-development',
      items: items,
      errors: null,
      selected: -1,
    };
  },
  watch: {
    items() {
      if (typeof this.changed === 'function') {
        this.changed(this.getData());
      }
    },
  },
  methods: {
    getData() {
      let res = [];
      for (let item of this.items) {
        if (!_.isEmpty(item)) res.push(item);
      }
      return res;
    },
    deleteItem(idx) {
      this.items[idx] = {};
      this.items = [...this.items];
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    browseFile(index) {
      this.selected = index;
      $(this.$refs.file).trigger('click');
    },
    async uploadFile(e) {
      const idx = this.selected;
      let file = e.target.files.length > 0 ? e.target.files[0] : null;
      if (!file) return;
      e.target.value = null;
      try {
        let parts = file.name.toLowerCase().split('.');
        if (parts.length < 2) return;
        let type = parts[parts.length - 1];
        let code = parts.slice(0, parts.length - 1).join('.');
        let id = this.objectId();
        let urlData = await uploadService.get({ filename: `image-${id}.${type}` });
        if (urlData) {
          let res = await axios.put(urlData.data.presignedUrl, file, {
            headers: {
              'Content-Type': `${file.type}`,
            },
          });
          let obj = {
            _id: id,
            dataCode: code,
            dataValue: urlData.data.imagePath,
            image: id,
          };
          this.items[idx] = obj;
          this.items = [...this.items];
        }
      } catch (err) {
        this.errors = err;
      }
    },
  },
};
</script>

<style lang="scss">
.be-avt-container {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 125px;
  &:hover {
    background-color: #ddd;
    .be-avt-del {
      display: block;
    }
  }
}
.be-avt {
  display: block;
  width: ato;
  height: 96px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 96px;
  text-align: center;
  color: #ccc;
  overflow: hidden;
  max-width: 100%;
}
.be-avt-code {
  display: block;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #333;
  overflow: hidden;
}
.be-avt-del {
  position: absolute;
  display: none;
  width: 28px;
  height: 28px;
  right: 0;
  bottom: 0;
  color: #555;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}
</style>
